import revive_payload_client_YTGlmQWWT5 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_VLCWq6w7BW from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_VTarsGAzgb from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/website/careers/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import gsapPlugin_HlwIIyl85L from "/opt/build/repo/website/careers/.nuxt/gsapPlugin.mjs";
import plugin_FweqFnuQMW from "/opt/build/repo/website/core/modules/3.seo-scripts/gtm/plugin.ts";
import i18n_dOTnCc6TUQ from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_VLCWq6w7BW,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  gsapPlugin_HlwIIyl85L,
  plugin_FweqFnuQMW,
  i18n_dOTnCc6TUQ,
  chunk_reload_client_SeG0EjL5Ec
]